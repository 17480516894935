<template>
  <div class="find-id-frame">
    <div class="modal-cover" v-show="isShowModal||isShowErrorModal"></div>
    <div class="header">
      <router-link to="/" class="btn-go-home">
        <img src="@/assets/images/IA/icon_home_w.png" alt="icon">
        <p>홈으로 이동</p>
      </router-link>
    </div>
    <div class="main-container" v-if="!isFindUser">
      <img src="@/assets/images/IA/img_primary_logo.png" alt="logo">
      <div class="find-id-form">
        <h1>비밀번호 찾기</h1>
        <p class="guide-text">
          회원가입 시 등록한 아이디, 이름, 휴대폰 번호로 찾을 수 있습니다. 정보 확인이 불가할 경우 jjkorom@gmail.com으로 연락주십시오.
        </p>
        <div class="label-box">
          <label>
            아이디
          </label>
          <input type="text" placeholder="영문 아이디 입력" class="custom-input-box" v-model="userId">
        </div>
        <div class="label-box">
          <label>
            이름
          </label>
          <input type="text" placeholder="이름 입력" class="custom-input-box" v-model="userName">
        </div>
        <div class="label-box">
          <label>
            휴대폰 번호
          </label>
          <input type="text" placeholder="'-'없이 번호 입력" class="custom-input-box" v-model="mobile"
                 v-on:keypress.enter.prevent="findPw">
        </div>
        <button class="custom-btn-box" @click="findPw">비밀번호 재설정</button>
      </div>
    </div>
    <UserRePassword v-else :userId="userId"/>
    <Progress :isProgress="isProgress"/>
    <ErrorDialog @callBackModal="callBackModal" v-show="isShowErrorModal"/>

  </div>
</template>

<script>

import {firebase} from "@/firebase/firebaseConfig";
import Progress from "@/components/Progress";
import ErrorDialog from "@/views/common/ErrorDialog";
import UserRePassword from "@/views/common/auth/user/UserRePassword";

export default {
  name: "UserFindPassword",
  components: {UserRePassword, ErrorDialog, Progress},
  data() {
    return {
      fbCollection: 'users',
      userId: '',
      userName: '',
      mobile: '',
      isProgress: false,
      isShowModal: false,
      isShowErrorModal: false,
      isFindUser: false,
      email: '',
      email2: '',
    }
  },
  methods: {
    findPw() {
      const self = this;
      if (self.userName.trim().length === 0) {
        alert('이름을 입력하세요.');
        return
      } else if (self.userId.trim().length === 0) {
        alert('아이디를 입력하세요.');
        return
      } else if (self.mobile.trim().length === 0) {
        alert('휴대폰 번호를 입력하세요.');
        return
      }
      const db = firebase.firestore();
      const defRef = db.collection(self.fbCollection);
      self.isProgress = true;
      defRef
          .where('name', '==', self.userName)
          .where('id', '==', self.userId)
          .where('mobile', '==', self.mobile).limit(1)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.size > 0) {
              querySnapshot.forEach((doc) => {
                doc.data();
                self.isProgress = false;
                self.isFindUser = true;
              });
            } else {
              self.email = '';
              self.isShowErrorModal = true;
            }
            self.isProgress = false;
          });
    },
    callBackModal: function (index) {
      const self = this;
      if (index === 0) {
        self.isShowModal = false
        self.$router.go(-1)
      } else if (index === 4) {
        self.isShowErrorModal = false
      }
    },
  }
}
</script>

<style scoped>

.custom-input-box {
  font-size: 16px;
}

.guide-text {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #0069CA;
  margin: 30px 0 29px;
}

strong {
  margin-left: 13px;
}

.find-id-frame {
  background-color: #A0B0C6;
}

.header {
  height: 80px;
  display: flex;
  flex-direction: row;
}

.btn-go-home {
  display: flex;
  margin-left: auto;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.btn-go-home img {
  width: 20px;
  height: 20px;
}

.btn-go-home p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  margin: 0 15px 0 5px;
}

.main-container {
  height: 779px;
  text-align: center;
}


.main-container img {
  width: 193px;
  height: 41px;
  margin-top: 64px;
  margin-bottom: 30px;
}

.find-id-form {
  margin: 0 auto;
  width: 418px;
  height: 571px;
  background: #FFFFFF;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
}

.find-id-form h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
}

.find-id-form h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #999999;
  margin-bottom: 40px;
}

.find-id-form h4 strong {
  color: #0069CA;
  cursor: pointer;
  margin-left: 8px;
}

.find-id-form label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #272833;
  margin-bottom: 10px;
  text-align: left !important;
}

.label-box {
  text-align: left;
  margin-bottom: 20px;
}

.btn-find {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #999999;
}

.btn-find:first-child {
  margin-right: 3px;
}

.btn-find:last-child {
  margin-left: 3px;
}

.custom-btn-box {
  margin-top: auto;
}
</style>


